<template>
  <div class="credits-page">
    <div class="credits-page__title">
      <img alt="applause icon" src="../assets/svg/applause.svg" v-svg-inline />
      Credits
      <img alt="applause icon" src="../assets/svg/applause.svg" v-svg-inline />
    </div>
    <div class="credits-page__content d-flex flex-column">
      <div class="credits-page__text">
        A huge thanks to my significant other
        <a
          class="credits-page__link"
          href="https://www.linkedin.com/in/leopons1993/"
          target="_blank"
        >
          Léo Pons
        </a>
        for his great help on design and technical support, without whom this
        project wouldn't have been possible.
      </div>
      <div class="credits-page__text">
        Most of icons and graphic resources are taken from
        <a
          class="credits-page__link"
          href="https://www.flaticon.com/"
          target="_blank"
        >
          Flaticon
        </a>
        and
        <a
          class="credits-page__link"
          href="https://www.freepik.com/"
          target="_blank"
        >
          Freepik
        </a>
        and produced by awesome authors listed below. Many thanks to them!
      </div>
      <div class="credits-page__list d-flex flex-wrap">
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/freepik"
          target="_blank"
          >Freepik</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/flat-icons"
          target="_blank"
          >Flat Icons</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/ultimatearm"
          target="_blank"
          >ultimatearm</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/ddara"
          target="_blank"
          >dDara</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/smashicons"
          target="_blank"
          >Smashicons</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/iconixar"
          target="_blank"
          >iconixar</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/dinosoftlabs"
          target="_blank"
          >Dinosoftlabs</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/surang"
          target="_blank"
          >surang</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/mynamepong"
          target="_blank"
          >mynamepong</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/pixel-perfect"
          target="_blank"
          >Pixel perfect</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/nikita-golubev"
          target="_blank"
          >Nikita Golubev</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/wanicon"
          target="_blank"
          >wanicon</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/monkik"
          target="_blank"
          >monkik</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/vitaly-gorbachev"
          target="_blank"
          >Vitaly Gorbachev</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/icongeek26"
          target="_blank"
          >Icongeek26</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/nhor-phai"
          target="_blank"
          >Nhor Phai</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/vectors-market"
          target="_blank"
          >Vectors Market</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/photo3idea-studio"
          target="_blank"
          >photo3idea_studio</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/those-icons"
          target="_blank"
          >Those Icons</a
        >
        <a
          class="credits-page__author credits-page__link"
          href="https://www.flaticon.com/authors/good-ware"
          target="_blank"
          >Good Ware</a
        >
      </div>
    </div>
    <div class="recipe-page__stock">
      <StockBox />
    </div>
  </div>
</template>

<script>
import StockBox from "@/components/StockBox.vue";

export default {
  name: "credits-page",
  components: {
    StockBox,
  },
};
</script>
